.inputBar {
  
  background-color: white;
  display: flex;
  width: 100%;
  
  
}

.bottomBar {
  
  background-color: white;
  
  padding-bottom: 10px;
  padding-top: 25px;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  
}

.InputText {
  
  width: 75%;
  
}

.sendIcon{
  
  padding: 10px;
 
}

.MessagePanel{

  padding: 30px;  
  width: 100%;
  
 
}


.MessageList{
  
width: 100%;
height: calc(90vh - 150px); 

overflow: auto; 

}

.MessageListSide{
  
width: 100%;
height: 45vh; 

overflow: auto; 

}

.paginationArrow{

  margin-left: 50%;

  margin-top: -30px;
}
 /*  Message Cards  */ 

 .messageContainerOwner{

  margin: 5px; 
  border: 0px solid grey; 
  background-color: rgba(25,77,125,0.05);
 }

 .messageContainerOther{

  margin: 5px; 
  border: 0px solid grey; 
  background-color: rgba(0,120,0,0.05);  
 }

 .messageDate{
  
  text-align:right;
  font-size:12px;
  padding:3px;
 }
.messageWrap{
  display:flex;
  padding: 7px;
  margin-top:-26px;
}

.messageAvatar{

}

.messageName{
  padding: 2px;
  margin-left:10px; 
  font-size:20px; 
  font-weight: bold;
}

.messageText{
  margin-left:67px; 
  margin-top:-20px; 
  padding-bottom:7px;
  font-size:20px;
}



@media screen and (max-width: 768px) {

.drawerContainer{

  display: none;
}

.MessagePanel{
  
  
  padding: 10px;
  
  width: 100%;
  
 
}

.paginationArrow{

  margin-left: 45%;
  margin-top: -10px;


}

.MessageList{
  
width: 100%;
height: calc(100vh - 180px); 

overflow: auto; 

}

.bottomBar {
  
  background-color: white;
  
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  position: sticky;
  bottom: 5px;
  width: 90%;


}
.InputText {
  
  width: 75%;
  
}

.sendIcon{
  display: flex;
  align-items: center;
  padding: 0px;
}

 /*  Message Cards  */ 

 .messageContainerOwner{

  margin: 5px; 
  border: 0px solid grey; 
  background-color: rgba(25,77,125,0.05);
  border-radius: 30px;
 }

 .messageContainerOther{

  margin: 5px; 
  border: 0px solid grey; 
  background-color: rgba(0,120,0,0.05); 
  border-radius: 30px; 
 }

 .messageDate{
  
  display: block;
  text-align:right;
  margin-right: 15px;
  margin-bottom: -12px;
  font-size:8px;
  padding:3px;
 }
.messageWrap{
  display:flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-top: 1px;

}

.messageAvatar{
  
  margin-top: -15px;
  margin-bottom: 5px;

  zoom: 0.8;

}

.messageName{
  display: none;
  padding: 2px;
  margin-left:10px; 
  font-size:20px; 
  font-weight: bold;
}

.messageText{

  margin-left:60px; 
  margin-top:-50px; 
  padding-right:9px;
  font-size:17px;
}

.messageImage{

  height: 20vh; 
  margin-top: 15px
}



}


