

.bottomBarFixed{

  position: fixed;
  bottom: 0;
  width: 100vw;  
  padding-top:0px;
  background-color: rgba(255,255,255,1);

}

.bottomBarRelative{

  position: relative;  
  
}

.mainContainer{


	background-color: rgba(250,250,250,1);
	border-bottom: solid 1px;
  width: 100vw;
  

}

.bottomLine{

	margin: 0px;
	border-top: 1px solid lightgray;

}

.barContainer{
  display: flex;
	margin: 10px;
  align-items: center;

}

.bottomBarMecenate{

  
  margin-right:10px;  
  margin-left: auto;
	font-size: 14px;
}

.bottomBarAbout{

	margin-left: 20px;
 
}


@media screen and (max-width: 768px) {



.bottomBarMecenate{

  margin-left: auto;
	font-size: 9px;
}

.bottomBarAbout{

    font-size: 11px;
	margin-left: 20px;
 
}



}