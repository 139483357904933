.aboutMainContainer{

	margin-left: 15vw;
	margin-right: 25vw;
	margin-bottom: 100px; 
}

.aboutMainTitle{

	font-size: 50px;
	font-weight: 300;
	text-transform: uppercase;
}

.aboutContent{

    text-align: justify;
    font-size: 17px;
    font-weight: 300;
}

.aboutSectionContainer{

	display: flex;
	width: fit-content;
	align-items: center;
	margin-top: 35px;
}

.aboutSectionContainer:hover{

	display: flex;
	align-items: center;
	margin-top: 35px;
	cursor: pointer;
	color: grey;

}

.aboutSectionTitle{

	font-size: 40px;
	font-weight: 300;
	margin-left: 0px;
	text-transform: uppercase;
}

.aboutHowItWorksSelector{

	display: flex;
	width: 100%;
	margin-left: 5vw;
	justify-content: center;
}

{/*########### HOW IT WORKS ####################*/}

.aboutHowItWorksContent{

	display: block;
	margin-left: 5vw;
	justify-content: center;
	margin-top: 50px;
}

.aboutHowItWorksPlayerWrapper{
	
	padding-top: 56.25%;
	position:relative;
}
.aboutHowItWorksPlayer{
    
	display: inline;
	position: absolute;
	top: 0;
	left: 0;
}

.aboutHowItWorksList{

	margin-top: 50px;
	font-weight: 300;;
	font-size: 22px;
	text-align: justify;
	line-height: 35px;;
}

.aboutHowItWorksListitem{

	margin-top: 20px;;
}



{/*########### REGISTRATION FORM ###############*/}

.RegistrationFormEntriesContainer{

	display: flex;
	flex-direction: column;
}

.RegistrationFormText{

	width: 100%;
	text-align: justify;
	font-weight: 400;
	font-size: 17px;
}

.RegistrationFormEntriesLink{

	color: rgb(212,0,0)
}

.RegistrationFormEntries{

	width: 100%;
}

.RegistrationFormMessage{

	width: 100%;
	margin-top: 20px;
}

.RegistrationFormButton{

	margin-top: 20px;
	align-items: flex-start;
}

{/*##################### CONTACT US ##################### */}

.aboutContactEntryContainer{

	display: flex;
	align-items: center;
}

.aboutContactEntrytext{

	margin-left: 10px;
	text-align: left;
}

.aboutContactFormHeader{

	font-weight: 400;

	margin-top: 50px;;
}



@media screen and (max-width: 768px) {


.aboutMainContainer{

	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 100px; 
}

.aboutMainTitle{

	font-size: 50px;
	font-weight: 300;
	text-transform: uppercase;
}

.aboutContent{

    text-align: justify;
    font-size: 15px;
    font-weight: 300;
}

.aboutSectionContainer{

	display: flex;
	width: fit-content;
	align-items: center;
	margin-top: 35px;
}

.aboutSectionContainer:hover{

	display: flex;
	align-items: center;
	margin-top: 35px;
	cursor: pointer;
	color: grey;

}

.aboutSectionTitle{

	font-size: 35px;
	text-align: left;
	font-weight: 300;
	margin-left: 5px;
	text-transform: uppercase;
}

.aboutHowItWorksSelector{

	display: flex;
	width: 100%;
	margin-left: 0vw;
	justify-content: center;
}

{/*########### HOW IT WORKS ####################*/}


.aboutHowItWorksContent{

	display: block;
	margin-left: 0px;;
	justify-content: center;
	margin-top: 50px;
}

.aboutHowItWorksPlayerWrapper{
	
	padding-top: 56.25%;
	position:relative;
}
.aboutHowItWorksPlayer{
    
	display: inline;
	position: absolute;
	top: 0;
	left: 0;
}
.aboutHowItWorksPlayerDesktop{

	display: none;
}

.aboutHowItWorksList{

	margin-top: 50px;
	font-weight: 300;;
	font-size: 22px;
	text-align: left;
	line-height: 35px;
}

.aboutHowItWorksListitem{

	margin-top: 20px;;
}

}