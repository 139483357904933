.mainAppBar{

	width: 100vw;	

}

.appBar{

	
}

.toolBar{

	background-color: white;
}

.toolBarContent{

	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;   
}

.logoBar{

	width: 40px;
}


.itemHome{
	margin-left: auto;
}


.howitworks{

	margin-left: 10px;
	border-style: solid;
	border-color: black;
	border-width: 1px;
	padding: 5px;
	cursor: pointer;
	animation: buttonBackground 2s infinite;
	
}

.howitworks:hover{

	background-color: rgba(152,0,0,0.1);
	animation: none;
}

@keyframes buttonBackground{
    0%    { background-color: rgba(0,0,0,0.1)}
    33%   { background-color: rgba(0,0,0,0.05)}
    66%   { background-color: rgba(0,0,0,0.05)}
    100%  { background-color: rgba(0,0,0,0.1)}
    
  }

  .AppBarDisplayNone{

	display: none;
  }


@media screen and (max-width: 768px) {

.appBar{
     
}


.toolBarContent{

	display: flex;
	justify-content: center;
	width: 100%;
	align-items: center;
    zoom: 0.7;
    -moz-transform: scale(0.7);	


}

.logoBar{
	
	width: 45px;
}


.itemHome{
	
	margin-left: 0px;
}




.howitworks{

	display: none;
	margin-left: 10px;
	border-style: solid;
	border-color: black;
	border-width: 1px;
	padding: 5px;
	cursor: pointer;
	animation: buttonBackground 2s infinite;
	
}
}



@media screen and (max-width: 350px) {

.appBar{
     
}

.howitworks{

	
	margin-left: 10px;
	border-style: solid;
	border-color: black;
	border-width: 1px;
	padding: 5px;
	cursor: pointer;
	animation: buttonBackground 2s infinite;
	
}


.toolBarContent{

	display: flex;
	justify-content: center;
	width: 85%;
	align-items: center;
    zoom: 0.7;
    -moz-transform: scale(0.7);	


}

.logoBar{
	
	width: 0px;
}


.itemHome{
	
	margin-left: 0px;
}


}