/* SETTING PERSONAL */


.SettingsPersonalPage {

  margin-left:  100px;
  margin-right:  100px;
}

.SettingsPersonalMainTitle{

	font-size: 50px;
}


.SettingsPersonalHeaders {

  margin-bottom: -55px;
  margin-top: 60px; 
  font-size: 22px;
}

.SettingsPersonalLine{

	color: black;
	background-color: white; 
	height: 0; 
	margin-top: 60px; 
	margin-bottom: 50px;  
	
}

.SettingsPersonalProfilePicWrap{

	display: flex;
	align-items: center;
}


.SettingsPersonalPic{

	height: 180px;
	border-radius: 100%;
	margin-right: 10px;
}

.SettingPersonalCropWrap{
   display: flex;

}

.SettingPersonalCropCanvas{

	margin-left: 10px;
    width: 200px;
}


.SettingsPersonalItem{

	display: flex; 

	align-items: baseline;
	
	font-size:18px;
}

.SettingsPersonalItemName{

	margin-right: 50px;
}

.SettingsPersonalItemInput{

	
}

.SettingsPersonalSubmitButton{

	float: right;
	margin-bottom: 100px;
	
}

/* SETTINGS PORTFOLIO */

.SettingsPortfolioMainTitle{

	font-size: 50px;
}

.SettingsPortfolioIntro{

	text-align: justify;
	font-variant: small-caps;
	font-weight: 500;
	font-size: 17px;
	background-color: rgb(250,250,250);
	padding-left: 15px;
	padding-right: 15px;

}

.SettingsPortfolioPics{

	
	height: 200px;
	margin-left: -100px;
}

.SettingsPortfolioPicDiv{

    display: flex;
    justify-content: center;
	width:500px;
	
	margin-right: 0px;
}

.SettingsPortfolioPicModule{

	display:flex;
	align-items: center;


	padding: 20px;

}

.SettingsPortfolioInput{

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 50%

}

.artworkSize{

max-width: 75px;



}

.artworkSizeContainer{

	margin-left: 10px;
	margin-right: 10px;
}

.artworkDescription{

 font-variant: small-caps;

 display:flex;
 align-items: center;
 

}

.artworkDescriptionDescription{

	margin-right: 10px;
}

.artworkTitle{

	width: 60%;
}

.artworkAuthor{

    width: 40%;
	margin-left: 10px;
}

/* SETTINGS COMMISSION */

.SettingsCommissionItem{

	display: flex; 
	font-size:18px;
	width: 100%;

}

.SettingsCommissionItemName{

	margin-right: 50px;
}

.SettingsCommissionEntry{
    font-variant: small-caps;
	width: 50%;
}

.SettingsCommissionPriceName{

	text-transform: uppercase;
}

.SettingsCommissionPriceEntryWrap{

	margin-left: 40px;
	margin-top: 14px;
}

.SettingsCommissionSizeEntryWrap{
	display: flex;
	align-items: center;
}

.SettingsCommissionSizeEntry{

	margin-left: 10px;
}

.SettingsCommissionPriceRangeEntryWrap{
	display: flex;
	align-items: center;
}

.SettingsCommissionTravelEntry{
    font-variant: small-caps;
	width: 50%;
	margin-top: 10px;
}





/* COMMISSIONS MODES  */

.commissionCardContainer{

  display: inline-flex;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  border-color: lightgray;
  font-variant: small-caps;
  width: 100%;

}

.commissionCardContainer:hover{


  border-width: 1px; 
  border-color: grey; 
  box-shadow: 1px 1px 10px lightgray;

  cursor: pointer;

}


.commissionCardPic{

  width: 200px;
  height: 125px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;  
}

.commissionCardText{

  text-align: justify;
  margin-left: 40px;
  width: 77%;
}

.commissionCheckbox{

  height: 25px;
}

.CommissionTypeContainer{

	display: flex;
	align-items: center;
}

/* SETTINGS ACCOUNT */

.settingsAccountModal{

  background-color: white; 
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top:40px;
  padding-bottom: 40px;
  border-radius: 10px;

  font-variant: small-caps;
}

.settingsAccountInside{

  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 10px;

  padding: 20px;
  box-shadow: 1px 1px 10px lightgray;
}

.settingsAccountModalTitle{

  font-size: 40px;
  margin-bottom: 50px;
  margin-top: 0px;
}

@media screen and (max-width: 768px) {

.SettingsPersonalPage {

  margin-left:  10px;
  margin-right:  10px;
}	

.SettingsPersonalMainTitle{

	font-size: 40px;
}

.SettingsPersonalHeaders {

  margin-bottom: -55px;
  margin-top: 50px; 
  font-size: 22px;
  font-weight: bold;
}

.SettingsPersonalLine{

	color: black;
	background-color: white; 
	height: 0; 
	margin-top: 60px; 
	margin-bottom: 20px;  
	
}

.SettingsPersonalProfilePicWrap{

	display: block;
}

.SettingsPersonalPic{

	height: 150px;
	border-radius: 100%;
	margin-bottom: 10px;
}

.SettingPersonalCropWrap{
   display: block;
   margin-top: 10px;
}

.SettingPersonalCropCanvas{
    
    display: none;
	margin-left: 10px;
    width: 200px;
}

.SettingsPersonalItem{

  display: flex; 
  flex-direction: column;
  font-size:18px;
}

.SettingsPersonalItemName{

	margin-right: 0px;
}

.SettingsPersonalItemInput{
	margin-top: -15px;
	margin-bottom: 20px;
	
}

.SettingsPersonalItemInputStatement{
	margin-top: -15px;
	margin-bottom: 20px;

	max-width: 90vw;
	
}

/* SETTINGS COMMISSION */

.SettingsCommissionItem{

	display: block; 
	font-size:18px;
	width: 100%;
}

.SettingsCommissionItemName{

	margin-right: 0px;
}

.SettingsCommissionEntry{
    font-variant: small-caps;
	width: 90%;
	margin-top: -25px;
    margin-bottom: 40px;
}

.SettingsCommissionPriceName{

	text-transform: uppercase;
}

.SettingsCommissionPriceEntryWrap{

	margin-top: -15px;
	margin-bottom: 40px;
	margin-left: 0px;
}

.SettingsCommissionPriceEntry{

	width: calc(90% - 100px);
}

.SettingsCommissionSizeEntryWrap{

	display: flex;
	align-items: center;
	margin-top: -15px;
	margin-bottom: 40px;
}

.SettingsCommissionSizeEntry{

	width: calc(45% - 30px);
	margin-right: 5px;
	margin-left: 5px;
}

.SettingsCommissionPriceRangeEntryWrap{

	display: flex;
	align-items: center;
	margin-top: -30px;
	margin-bottom: 20px;
}

.SettingsCommissionPriceRangeEntry{

	width: calc(45% - 30px);
}

.SettingsCommissionTravelEntry{
    font-variant: small-caps;
	width: 50%;
	margin-top: -20px;
}

/* COMMISSIONS MODES  */

.commissionCardContainer{

  display: block;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  border-color: lightgray;
  font-variant: small-caps;
  width: 100%;
  padding: 10px;

}



.commissionCardPic{
  
  display: block;
  width: 200px;
  height: 125px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;  
}

.commissionCardText{

  text-align: justify;
  margin-left: 0px;
  width: 100%;
}

.commissionCheckbox{

  height: 25px;
}

.CommissionTypeContainer{

	display: flex;
	align-items: center;
}

/* SETTINGS PORTFOLIO */

.SettingsPortfolioMainTitle{
	font-size: 40px;
}

.SettingsPortfolioPics{
	
	height: 200px;
	max-width: 95vw;
	margin-left:auto;
	margin-right: auto;
}

.SettingsPortfolioPicDiv{

    display: flex;
    width: 95vw;
	margin-left:auto;
	margin-right: auto;
}

.SettingsPortfolioPicModule{

	display:block;
	padding: 0px;
	margin-left:auto;
	margin-right: auto;

}

.SettingsPortfolioInput{

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 60px;
	width: 95%;
	zoom: 0.8;

}

.artworkSize{

	max-width: 75px;
}

.artworkSizeContainer{

	margin-left: 10px;
	margin-right: 10px;
}

.artworkDescription{

 	font-variant: small-caps;
 	display:flex;
	align-items: center;
 

}

.artworkDescriptionDescription{

	margin-right: 10px;
}

.artworkTitle{

	width: 60%;
}

.artworkAuthor{

    width: 40%;
	margin-left: 10px;
}

}


