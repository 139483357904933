

.background{

	display: flex;
	justify-content:center; 
	background-image: url('./cretto_burri.jpg'); 

	max-width:100vw;
	height: 100vh; 
	background-size: cover;
	background-position: center;

}

.preContainer{
	margin-top:200px; 

	display:flex;
	justify-content:center;
	background-color: rgba(255, 255, 255, 0.95)
}

.containerContent{

	display:flex;
	flex-direction:column;
	padding: 30px; 
	width:350px
}

.logo{
	width: 300px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:0px;
}

.inputsContainer{
	 padding: 20px; 
	 display:flex; 
	 flex-direction:column;
	 justify-content:space-around;
	 height:200px;
}

.loginGoogleButton{
	 
	margin-left:auto;
	margin-right:auto; 
	margin-bottom:10px;

}

/* Register after Google */

.welcomeToMecenate{
	
	font-variant: small-caps; 
	color: black; 
	font-size: 29px;
	text-align: center; 
}

.pleseChoose{
	font-variant: small-caps; 
	color: rgba(0,0,0,0.7);
	text-align: center; 	
	font-weight: bold;
}

.inputAccountType{

	background-color:rgba(255, 255,255,0.7); 
    border-color: grey;
    border-style: solid; 
    border-width: 1px;
    border-radius: 10px; 
    padding-left: 15px;
    padding-right: 10px; 
    padding-bottom: 2px;
}

.textContainer{

	background-color: rgba(255,255,255,0.9);
	text-align: justify;
	padding: 10px;
	font-weight: '500';
	margin-bottom:5px;


}


@media screen and (max-width: 768px) {

.background{

	background-image: url('./cretto_burri.jpg');
	background-position: 50% -100px;
	height: 150vh; 
	

}

.preContainer{

	
	margin-top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.9)
}

.containerContent{

	display:flex;
	
	max-width: 80vw;
	border-width: 1px;
	flex-direction:column;
	margin-top: 100px;
	margin-right: auto;
	margin-left: auto;
	padding: 20px; 
	
}

}