.PictureGridMain {

      display: grid;
      grid-template-columns: 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw;
      grid-template-rows: 12vh 12vh 12vh 12vh 12vh 12vh;
      
    }

.imageGridCards {

  /* box-shadow: 1px 1px 12px rgba(70,70,70); */


  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: 50% 50%;
  grid-area: 1 / 1 ;
}


.overlayGridCards{

display: grid;
grid-area: 1 / 1;
opacity: 0;

margin-top: 10px;
margin-bottom: 1.2em;

 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlayGridCards:hover{

 height: 100%;
 width: 100%;
 margin-top: 0;	 

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  transition: background-color .15s linear;
}



.overlayColorGridCards{

 position: relative;
 opacity: 1;
 
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColorGridCards:hover{

 display: flex; 

 background-color: rgba(255,255,255,0.8);
 color: black;
 opacity: 1;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}


.overlayTextGridCards{
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  
}

.overlayNameGridCards{

  font-size: 50px;
  text-transform: uppercase;
  color: rgb(75,75,75);
}


@media screen and (max-width: 768px) {

.PictureGridMain {

      display: grid;
      grid-template-columns: 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw;
      grid-template-rows: 4vh 4vh 4vh 4vh 4vh 4vh;
      
    }  



.imageGridCards{

  /* box-shadow: 1px 1px 12px rgba(70,70,70); */
  

  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: 50% 50%;

  margin-top: 0px;
  margin-bottom: 1.2em;

  grid-area: 1 / 1 ;
}


.overlayGridCards{

display: grid;
grid-area: 1 / 1;
opacity: 0;
margin-top: 10px;


 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlayGridCards:hover{

height: 100%;
 width: 100%;
 margin-top: 0;  

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  transition: background-color .15s linear;
}



.overlayColorGridCards{

 position: relative;
 opacity: 1;
 height: 100%;
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColorGridCards:hover{

 display: flex; 

 background-color: rgba(255,255,255,0.8);
 color: black;
 opacity: 1;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}


.overlayTextGridCards{
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  
}

.overlayNameGridCards{

  font-size: 12px;
  font-variant: small-caps;
  color: rgb(75,75,75);
}



}








