.imageContainerShortcut {

  
  display: grid; 
  
  margin-top: 0;

   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
 

}

.imageShortcut {

  /* box-shadow: 1px 1px 12px rgba(70,70,70); */
  border-width: 3px;
  border-style: solid;
  border-color: rgba(220,220,220);

  height: 300px;
  width: 100%;

  object-fit: cover;
  object-position: 50% 50%;

  margin-top: 0px;
  margin-bottom: 1.2em;

  grid-area: 1 / 1 ;
}


.overlayShortcut{

display: grid;
grid-area: 1 / 1;
opacity: 0;
border-style: solid;
border-color: rgba(220,220,220);
border-width: 3px;
margin-top: 10px;
margin-bottom: 1.2em;

 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlayShortcut:hover{


 width: 100%;
 margin-top: 0;	 

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  transition: background-color .15s linear;
}



.overlayColorShortcut{

 position: relative;
 opacity: 1;
 
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColorShortcut:hover{

 display: flex; 

 background-color: rgba(255,255,255,0.8);
 color: black;
 opacity: 1;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}


.overlayTextShortcut{
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  
}

.overlayNameShortcut{

  font-size: 50px;
  font-variant: small-caps;
  color: rgb(75,75,75);
}


@media screen and (max-width: 768px) {


  .imageContainerShortcut {

  
  display: grid; 
  
  margin-top: 0;

   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
 

}

.imageShortcut {

  /* box-shadow: 1px 1px 12px rgba(70,70,70); */
  border-width: 3px;
  border-style: solid;
  border-color: rgba(220,220,220);

  height: 70px;
  width: 100%;

  object-fit: cover;
  object-position: 50% 50%;

  margin-top: 0px;
  margin-bottom: 1.2em;

  grid-area: 1 / 1 ;
}


.overlayShortcut{

display: grid;
grid-area: 1 / 1;
opacity: 0;
border-style: solid;
border-color: rgba(220,220,220);
border-width: 3px;
margin-top: 10px;
margin-bottom: 1.2em;

 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlayShortcut:hover{


 width: 100%;
 margin-top: 0;  

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  transition: background-color .15s linear;
}



.overlayColorShortcut{

 position: relative;
 opacity: 1;
 
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColorShortcut:hover{

 display: flex; 

 background-color: rgba(255,255,255,0.8);
 color: black;
 opacity: 1;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}


.overlayTextShortcut{
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  
}

.overlayNameShortcut{

  font-size: 12px;
  font-variant: small-caps;
  color: rgb(75,75,75);
}



}








