.canvas{


	display: grid;
    grid-area: 1 / 1  ;

    margin-left: auto;
    margin-right: auto;

   -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
	border-width: 1px;
	border-color: grey;
	border-style: solid; 

	
}


.canvasContainer{

   display: grid; 
  
  margin-top: auto;

   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

