.notificationFollow{
    display: flex;
    align-items: center;
	padding-right: 5px;
	padding-left: 5px;
	height: 55px;	


}

.notificationFollow:hover{

	
	background-color: rgba(220, 220, 220,0.25);
	

}

