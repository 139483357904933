.backgroundPswd{

	display: flex;
	justify-content:center; 
	background-image: url('./cretto_burri.jpg'); 

	max-width:100vw;
	height: 100vh; 
	background-size: cover;
	background-position: center;

}

.containerPswd{
	background-color: rgba(255, 255, 255, 0.7)
}

.insideContainerPswd{

	display:flex; 
	flex-direction:column;
	padding: 30px; 
	width:350px;
}

.logoPswd{

	width: 180px;
	margin-left:auto;
	margin-right:auto;
}

.inputContainerPswd{

	background-color: rgba(255, 255, 255, 0.6);
	padding: 10px;
}

.inputTitlePswd{

	font-variant: small-caps;
	color: black;
	font-size: 30px; 
}

@media screen and (max-width: 768px) {

.backgroundPswd{

	
	background-color: red;
	background-position: 50% -100px;
}

.containerPswd{
	background-color: rgba(255, 255, 255, 0.7);
	
}

}