
.logoHome{

	
	display: flex;
	justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 50vw;
}

.logoHomeContainer{

  padding-top: 70px;
  padding-bottom: 10px;
  background-color: rgba(195,195,195,0.05)
}

.searchBarContainer{

  background-color: rgba(195,195,195,0.05)
}

.searchBar {

border-style: solid;
border-width: 1px; 
border-color: grey; 
border-radius: 5px; 
width:40vw; 

display: flex; 
justify-content: center; 
margin-left: auto; 
margin-right: auto; 
margin-top: 0px;
text-transform: uppercase;


}

	.searchBar:hover{

		
		border-width: 1px; 
		border-color: grey; 
		box-shadow: 1px 1px 10px grey;
    cursor: pointer;


	}

	.searchBar:focus-within {
	  border-width: 1px; 
		border-color: black; 
}

.advancedSearch{  

  flex-direction: column;
  background-color: rgba(195,195,195,0.05); 
  padding: 2px;
  padding-top: 10px;
  margin-left:auto; 
  margin-right:auto; 
  margin-top:0px; 
  justify-content:center;



}

.advancedSearchoff{  
  display: none;
}

.radioGroup{
  display: flex; 
  margin-top:3px; 
  justify-content:center
}
.advancedSearchBlock{
  display: flex; 
  justify-content:center;
  align-items: flex-start;
  font-variant: small-caps; 
}

.advancedSearchItem{
  width:150px; 
  padding:10px;
  margin-left: 10px;
   
}
.advancedSearchPriceRange{
  display: flex;
  color: rgb(100,100,100);
  flex-direction: column;
  width:300px; 
  padding:10px;
  padding-top: 30px;
  margin-left: 10px;
  margin-right: 15px;

}

.advancedSearchPriceRangeTitle{

  margin-bottom: -10px;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;

}


.advancedSearchPriceRangeLabelsContainer{
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: -5px;

}
.advancedSearchPriceRangeLabels{
  display: flex;
  align-items: center;
  padding-left:5px;
  padding-right:5px;
  height: 25px;
  width: 110px;
  background-color: rgba(255,255,255,0.2);
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  border-radius: 4px;

}
.searchByName{
  display: flex;
  flex-direction: column;
  color: rgb(100,100,100);
  align-items: center;
  padding-top: 0px;
  font-size: 23px;
}

.searchByNameSwitch{

  margin-top: -26px;
}


.grid {

  margin-left: auto;
  margin-right: auto;


  padding-left: 170px;
  padding-right: 170px;
  margin-top: 50px;
  columns:  3;

  
  column-gap: 2em;
  column-fill: initial;


   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  
   
}

.gridColumn {

  -webkit-columns: 100vw 1;
  column-gap: 2em;
   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  break-before: column;
 
}

.gridColumn2 {

 display: none;
 
}

.gridColumnMobile {

  display: none;
 
}


.image {

  box-shadow: 1px 1px 12px rgba(70,70,70);
  border-width: 3px;
  border-style: solid;
  border-color: rgba(220,220,220);

  margin-top: 0px;
  margin-bottom: 1.2em;

   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; 
  grid-area: 1 / 1 ;
}

.imageDiv {

  
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  grid-area: 1 / 1 ;
}



.imageContainer {

  
  display: grid; 
  
  margin-top: 0;

   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
 

}



.overlay{

display: grid;
grid-area: 1 / 1;
opacity: 0;
border-style: solid;
border-color: rgba(220,220,220);
border-width: 3px;
margin-top: 10px;
margin-bottom: 1.2em;

 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlay:hover{


 width: 100%;
 margin-top: 0;	 

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}



.overlayColor{

 position: relative;
 opacity: 1;
 
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColor:hover{

 background-color: rgba(255,255,255,0.6);
 color: black;
 opacity: .95;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}

.overlayTopCard{

display: inline-block;

background-color: rgba(255,255,255,0.95);
margin-left: 3px;
margin-top: 3px;
padding-left: 10px;
padding-right: 10px;
height: 50px;
transition: background-color .1s linear;
}


.overlayBottomCard{

display: block;
flex-direction: column;
position: absolute;
bottom: 0;

height: 50px;
width:94%;

background-color: rgba(255,255,255,1);
margin-left: 3px;
margin-bottom: 3px;
padding-left: 10px;
padding-right: 10px;


transition: background-color .1s linear;
}

.overlayBottomCardTop{
  display: flex;
  width: 100%;
  padding-top: 2px;
  
  
}

.overlayBottomCardBottom{
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 0px;
  margin-left: -1px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 27px;
  
}

.overlayName{

margin-top: 0;
font-weight: 500;
font-size: 17px;
text-transform: uppercase;


}

.overlayUserType{

margin-top: -22px;
font-size: 20px;
font-variant: small-caps;


}

.overlayTitleHeader{


margin-top: 0;
font-weight: 500;
font-size: 15px;
text-transform: uppercase;
}

.overlayTitle{

max-width:50%;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
margin-left: 2px;
margin-top: 0;
font-size: 15px;
text-transform: uppercase;
font-weight: 350;
}

.overlayAuthorHeader{

margin-left: 10px;
margin-top: 0;
font-weight: bold;
font-size: 15px;
text-transform: uppercase;
font-weight: 500;
}

.overlayAuthor{


white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

margin-left: 2px;
margin-top: 0;
font-size: 15px;
text-transform: uppercase;
font-weight: 350;
}


.overlayDescription{

white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width:95%;

margin-top: 0px;
margin-left: 2px;
font-size: 15px;
text-transform: uppercase;
font-weight: 350;



}

.nameCardContainer{

display: flex; 
background-color: white;
margin-top: auto;
margin-bottom: 20px;
border-width: 1px;
border-style: solid;
border-color: lightgrey;
height: 100px;

-webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
}


.nameCardContainer:hover{

display: flex; 
background-color: rgba(150,160,180,0.1);
color: black;
margin-top: auto;
border-width: 1px;
border-style: solid;
border-color: lightgrey;
height: 100px;

-webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
}


.nameCardImg{


height: 96px;
margin-top: 2px;
margin-left: 2px;

-webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
}

.nameCardName{

height: 30px;
width: 80%;
margin-left: 15px;
margin-top: 10px; 
font-weight: 500;
font-size: 17px;
text-transform: uppercase;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;

}

.nameCardUser_type{

height: 20px;
width: 90%;
margin-top: -18px;
margin-left: 16px;
text-transform: uppercase;
font-size: 17px;
text-overflow: ellipsis;



}

.newOnMecenate{
 
  margin-top: 30px;

  
}

.newOnMecenateGrid{

  margin-left: auto;
  margin-right: auto;
  padding-right: 50px;
  margin-top: 20px;
  columns:  4;
  -webkit-columns: 4;
  column-gap: 1em;
  column-fill: initial;
}

.newOnMecenateNone{

  display: none;
}

.newOnMecenateTitle{

  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 25px;
  font-weight: 300;
  font-variant: small-caps;
  color: black;
}

.magMecenateBannerGrid{

  display: grid;
  grid-auto-columns: 4vw;
  grid-auto-rows: 4vh;
}

.magMecenateBannerContainer{

  grid-column: 2/6;
  grid-row: 2/7;
  
}

.magMecenateBannerImage{
  
  
}

.magMecenateBannerText{

  grid-column: 8/26;
  grid-row: 4/8;
  text-transform: uppercase;
  font-size: 20px;
}

.magMecenateBannerLink{
  
  grid-column: 1/26;
  grid-row: 1/8;
  background-color: rgba(0,0,0,0.03)

}




@media screen and (max-width: 768px) {

.logoHome{

  width: 85vw;
  justify-content: center;
  margin-top: -30px;


  
}

.searchBar {

  border-style: solid;
  border-width: 1px; 
  border-color: grey; 
  border-radius: 5px; 
  width:80vw; 
  height: 40px;
  display: flex; 
  justify-content: center; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: 5px;
}

.advancedSearch{  

  flex-direction: column;
  background-color: rgba(150,160,180,0.1); 
  width:95vw;
  margin-left:auto; 
  margin-right:auto; 
  margin-top:10px; 
  justify-content:center;

  border-radius: 1.5%;

}

.advancedSearchoff{  
  display: none;
}

.radioGroup{
  display: flex; 
  padding:10px; 
  justify-content:center
}
.advancedSearchBlock{
  display: block;
  width: 95vw;
  
}

.advancedSearchItem{
  width:85vw;   
}

.advancedSearchPriceRange{
  display: flex;
  color: rgb(100,100,100);
  flex-direction: column;
  justify-content: center;
  width:90%; 
  padding:10px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;

}

.searchByName{
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {

  display: flex;
  flex-direction: column;

  width: 90%;

  margin-left: auto;
  margin-right: auto;


  padding-left: 0px;
  padding-right: 0px;
  margin-top: 50px;
  -webkit-columns: 90vw 1;

  
  column-gap: 2em;
  column-fill: initial;


   -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  break-before: column;
  
   
}

.gridColumn {

  display: none;
 
}

.gridColumnMobile {

  display: block;
 
}

.overlay{

display: grid;
grid-area: 1 / 1;
opacity: 0;
border-style: solid;
border-color: rgba(220,220,220);
border-width: 3px;
margin-top: 10px;
margin-bottom: 1.2em;

 -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}


.overlay:hover{


 width: 100%;
 margin-top: 0;  

 opacity: 1;
 
 grid-area: 1 / 1  ;

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}



.overlayColor{

 position: relative;
 opacity: 1;
 
 grid-area: 1 / 1 ;
 opacity: 0;
 
 
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.overlayColor:hover{

 background-color: rgba(255,255,255,0.4);
 color: black;
 opacity: .95;
 border-style: solid;
 border-color: rgba(255,255,255,0);
 grid-area: 1 / 1 ;
 margin-top: 0px;
 

  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  transition: background-color .15s linear;
 
}

.overlayTopCard{

display: inline-block;

background-color: rgba(255,255,255,0.7);
margin-left: 3px;
margin-top: 3px;
padding-left: 10px;
padding-right: 10px;
height: 50px;
transition: background-color .1s linear;
}


.overlayBottomCard{

display: block;
flex-direction: column;
position: absolute;
bottom: 0;

height: 50px;
width:94%;

background-color: rgba(255,255,255,0.95);
margin-left: 3px;
margin-bottom: 3px;
padding-left: 10px;
padding-right: 10px;


transition: background-color .1s linear;
}

.overlayBottomCardTop{
  display: flex;
  width: 100%
}

.overlayBottomCardBottom{
  display: flex;
  align-items: flex-start;
  width: 100%;
  
  margin-left: -1px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 25px;
  
}

.newOnMecenate{
 
  margin-top: 0px;
  margin-left: 0px;
  width: 100vw;
  overflow: hidden;
}

.newOnMecenateGrid{

  margin-left: auto;
  margin-right: auto;


  
  padding-right: 20px;
  margin-top: 20px;
  -webkit-columns:  4;
  columns: 4;
  
  column-gap: 1em;
  column-fill: initial;
}

.newOnMecenateNone{

  display: none;
  width: 100vw;
  overflow: hidden;
}

.newOnMecenateTitle{

  font-size: 15px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-left: 5px;
}

.magMecenateBannerGrid{

  display: grid;
  grid-auto-columns: 4vw;
  grid-auto-rows: 2vh;
}

.magMecenateBannerContainer{

  grid-column: 2/10;
  grid-row: 2/7;
  overflow: hidden;
}

.magMecenateBannerImage{

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;
}

.magMecenateBannerText{

  grid-column: 11/26;
  grid-row: 2/8;
  text-transform: uppercase;
  font-size: 8px;
  text-align: justify;
  padding: 10px;
}

.magMecenateBannerLink{
  
  grid-column: 1/26;
  grid-row: 1/8;
  background-color: rgba(0,0,0,0.03)

}

}







