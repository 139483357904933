.userMain{
  display:flex;
}

.portfolio {

  
  width: 800px;
}

.header {

  
  margin-top: 0px; 
}

.headerContainer{

  
  width: 18vw;


}

.header-img {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -15px;

}

.header-img-circle-none {

 
  margin-top: 30px;
  
}

.header-img-circle {

  border-style: solid;
  border-width: 2px;
  border-color: white;
  margin-top: 30px;
  border-radius: 100%;
  padding: 2px;
  width: 170px;
}

.header-img-circle:hover {

  border-style: solid;
  border-width: 2px;
  border-color: rgba(24,86,143,0.5);
  margin-top: 30px;
  border-radius: 100%;
  padding: 2px;
  box-shadow: 3px 3px 15px rgba(24,86,143);
  transition: border-color .4s linear;
  cursor: pointer;
}

.header-img-img {

  
  width: 170px;
  border-radius: 100%;
  margin-top: 0px;
  
}

.userHeaderUserType{

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;

}

.header-name{

  display: flex;
  font-weight: 500;
  text-transform: uppercase;
  flex-direction: column;
  text-align: center;
  align-items: center;

}


.header-statement-social {
  
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  white-space: normal;
  max-width: 18vw;
  overflow-wrap: break-word;
  text-transform: uppercase;

}


.header-statement{
 
  display: flex;
  text-align: center;
  align-items: center;
  white-space: normal;
  max-width: 18vw;
  overflow-wrap: break-word;
  margin-top: -20px;
  padding-left: 20px;
  
  
}

.header-social{

  display:flex;
  align-items: center;
  margin-top: -15px;
  
}


.userInfo{
  display:flex;
  flex-direction: column;
  max-width: 18vw;
  white-space: normal;
  overflow-wrap: break-word;
  text-transform: uppercase;
  margin-top: 20px;
}

.infoEntry{

  display:flex;
  flex-wrap: wrap;

 
}

.gallery {

  display: flex;
  flex-direction: column;

  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  height: 90vh;
  padding-right:15px;
  padding-left:15px;

   
}

.gallerySettingsButton{

  display:flex;
  align-items: center;
  margin-left: 68vw;
  margin-top: -25px;
  border-radius: 100%;
  animation: blinkingBackground 2s infinite;
}

@keyframes blinkingBackground{
    0%    { color: rgba(24,86,143,1)}
    33%   { color: rgba(24,86,143,0.5)}
    66%   { color: rgba(24,86,143,0.7)}
    100%   { color: rgba(24,86,143,1)}
    
  }

.gallerySettingsButton:hover{

  display:flex;
  align-items: center;
  color: rgba(24,86,143,1);
  width: 24px;
  height: 24px;
  border-style: solid;
  border-width: 0px;
  border-color: rgba(24,86,143,0.5);
  box-shadow: 3px 3px 15px rgba(24,86,143);
  transition: border-color .4s linear;
  cursor: pointer;
  

}

.SideLeftSettingsButton{

  margin-left: auto;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  animation: blinkingBackground 2s infinite;
}

.SideLeftSettingsButton:hover{

  
  align-items: center;
  color: rgba(24,86,143,1);
  width: 24px;
  height: 24px;
  
  border-style: solid;
  border-width: 0px;
  border-color: rgba(24,86,143,0.5);
  box-shadow: 3px 3px 15px rgba(24,86,143);
  transition: border-color .4s linear;
  cursor: pointer;
  }

.CommissionSettingsButton{

  
  border-radius: 100%;
  width: 24px;
  height: 24px;
  animation: blinkingBackground 2s infinite;
}

.CommissionSettingsButton:hover{

  
  align-items: center;
  color: rgba(24,86,143,1);
  width: 24px;
  height: 24px;
  
  border-style: solid;
  border-width: 0px;
  border-color: rgba(24,86,143,0.5);
  box-shadow: 3px 3px 15px rgba(24,86,143);
  transition: border-color .4s linear;
  cursor: pointer;
  }

.gallerySettingsButtonNone{

  display:none;
}





.userBar {

  display: flex;
  margin-bottom: 3px;
  justify-content: center;
}

.profileContent {

  
  background-color: rgba(0,0,0, 0.04);
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;

  
}

.profileContentHeader {

  display: flex;

  margin-top: 50px;
  font-size: 20px;
  
  
  
}

.left-bar {

   
  padding-right: 20px;
  border-right: 1px solid grey;

  height: auto;
  
  
}

.followButton {

   display: flex;
   justify-content: center;
   margin-top:5px;
  
}



.galleryDescription{

  display: flex;
  flex-direction: row;
  font-variant: small-caps; 
  font-size:17px; 
  margin-top: -40px; 

}

.galleryDescriptionOff{

  display: none;
}


.galleryDescriptionTitle{

font-weight: 500;
text-transform:uppercase;
margin-right: 5px

}

.galleryDescriptionEntry{

margin-right: 5px;
text-transform:uppercase;

}

/* COMMISSIONS PAGE */

.userCommissionMain{
  display: flex;
  flex-direction: column;
}

.commissionText{

  font-size: 17px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 10px;
  margin-bottom: 25px;
  margin-top: 35px;

}

.commissionCardContainer2{

  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  margin-top: 0px;
  border-color: lightgray;
  font-variant: small-caps;
  
}

.commissionCardContainer2:hover{

  border-width: 1px; 
  border-color: grey; 
  box-shadow: 1px 1px 10px lightgray;
  cursor: pointer;
}


.commissionCardPic2{

  width: 200px;
  height: 125px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;  
}

.commissionCardText{

  text-align: justify;
  margin-left: 40px;
  width: 77%;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 200;

}

.commissionCardTextHeader{

  font-weight: 400;
  text-transform: uppercase;
  font-size: 22px;
}

.commissionModal{

  background-color: white; 
  width: 70vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top:10px;
  padding-bottom: 10px;
  border-radius: 0px;

  text-transform: uppercase;
}

.commissionModalInside{

  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 0px;

  padding: 20px;
  box-shadow: 1px 1px 10px lightgray;
}

.commissionModalCloseIcon{

  display: none;
  position: relative;
  margin-left: calc(100% - 25px);
  cursor: pointer;
}

.commissionModalTitle{

  font-size: 30px;
  font-weight:500;
  margin-bottom: 10px;
  margin-top: 0px;
}

.commissionModalSubTitle{

  font-size: 18px;
  font-weight:400;
  margin-bottom: 40px;
  margin-top: 0px;
}


.commissionModalBar{

  display:flex;
  flex-wrap: wrap;
  width: 100%;



}

.commissionModalBarMedia{

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;

}


.commissionModalDimensionBlock{

  display: flex;
  padding: 10px;
}

.commissionModalEntryText{

  margin-right: 10px;
  margin-left: 30px;
  font-weight: 500;
  text-transform: uppercase;
}

.commissionModalEntryEmail{

  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 2px;

}


.commissionModalEntryPrice{

  display: flex;
  font-size: 40px;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 0px;
  font-weight: 500;
}



.commissionModalEntryDimensionBlock{

  display: flex;
}

.commissionModalEntryUnits{

  position: relative; 
  bottom: 0; 
  font-size: 90%;
  margin-left: 5px;
}

.commissionModalEntryMedia{

 height: 42px;
 width: auto;
}

.commissionModalEntryDimension{

 width: 77px;
}

.commissionModalMessageText{

 text-align: center;
 margin-top: 50px;
 font-size: 18px;
 font-weight: 400;
}


.commissionModalMessage{

 width: 100%;

}

.commissionModalSendButton{

margin-top: 10px;
text-align: right;

}


/* MODAL NOTIFICATION */

.modalMenuNameContainer{
  display: flex;
  align-items: center;

}

.modalMenuName{
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;

}

.modalMenuEntriesContainer{

  line-height:0.2;
  margin-top: 30px;
}

.modalMenuMessageContainer{

  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  margin-top: -15px;
}

.modalMenuEntry{
  font-weight: bold;
  margin-right: 10px;
}

.modalMenuEntryContainer{
  display: flex;
}


.socialMediaSharing{

  display: flex;
  position: absolute; 
  bottom: 5px; 
  right: 15vw;
}

.commissionInactive{

  display: flex;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  margin-top: 0px;
  border-color: lightgray;
  font-variant: small-caps;
  opacity: 0.35;
}

{/*
###############################################################
###############################################################
###############################################################
###############################################################
###############################################################
*/}

@media screen and (max-width: 768px) {

.userMain{

  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: visible;
  overflow-y: visible;  
}

.left-bar {

  display: block; 
  padding-right: 0px;
  border-right: 0px solid grey;
  width: 100vw;
 
  margin-bottom: -30px;
  
}

.headerContainer{

  display:block;
  background-color: rgba(166, 186, 197, 0.07);
  width: 100vw;
  padding:0px;
  overflow: hidden;

}

.pictureAndName{

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: -20px;

}

.header-img-circle {


  border-style: solid;
  border-width: 0px;
  border-color: white;
  margin-top: 30px;
  border-radius: 100%;
  padding: 2px;
  width: 60px;
}

.header-img-circle:hover {

  border-style: solid;
  border-width: 0px;
 
}


.header-img-img {
 
  width: 60px;
  border-radius: 100%;
  margin-right: 10px;
  
}

.userHeaderUserType{

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1px;
  margin-bottom: -5px;
  font-size: 12px;
  font-weight: bold;

}



.header-name { 
  
  
  line-height: 1;
  font-size: 18px;
  white-space: normal;
  max-width: 100vw;
  overflow-wrap: break-word;
  margin-left: 10px;


}

.header-statement-social{
  
  display: block;
  position:static;
  margin-left: 65px;
  margin-top: -40px;
  font-size: 12px;
  max-width: 100vw; 
 
}

.header-statement{

   display: flex;
  text-align: center;
  align-items: center;
  white-space: normal;
  max-width: 80vw;
  overflow-wrap: break-word;
  margin-top: -20px;
  padding-left: 20px;

}

.header-social{

  display:flex;
  align-items: center;
  margin-top: -12px;
  margin-left: 10vw;
}

.userInfo{
  display:none;
}

.gallery {

  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 0;
  margin-right: 0;
  width: 100vw;
  padding-right:0px;
  padding-left:0px;
 
  
  overflow-y: visible;
   
}

.gallerySettingsButton{

  display:none;
}

.SideLeftSettingsButton{

  display:none;
}

.CommissionSettingsButton{

  display:none;
}

.galleryWrap{
  display: flex;
  flex-direction: column;
  height: 10%;
  margin-top: 10px;
  margin-left: 0px;
}

.galleryDescription{

  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  font-variant: small-caps; 
  font-size:17px; 
  line-height: 0.0001;
  
}

.socialMediaSharing{

  display: flex;
  flex-direction: column;
  width:50px;
  padding-top: 10px;
  position: sticky;
  bottom:30vh;
  margin-right: auto;
  margin-left: 5px;
  background-color: rgba(255,255,255,.7);
  border-radius:30px;
}

.followButton {

   display: flex;
   justify-content: flex-start;
   padding-left: 15px;
   margin-left: auto;
   margin-top:0px;
   height: 17px;
   margin-bottom: 25px;
   padding-bottom: 5px;
   border-bottom: 1px solid lightgray;
   background-color: rgba(166, 186, 197, 0.07);
}

.userBar {

  display: flex;
  margin-bottom: 1px;
  margin-top: 0px;
  justify-content: center;
  width: 100vw;
  
}


/* COMMISSIONS PAGE */

.userCommissionMain2{
  display: block;
  
  
}

.commissionText{

  width: 85%;
  font-size: 16px;
  font-weight: 300;
  font-variant: small-caps;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  margin-top: 20px;
  line-height: 1.2;
  text-align: center;


}

.commissionInactive{

  display: flex;
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 1px;
  box-shadow: 1px 1px 10px lightgray; 
  border-color: lightgray;
  font-variant: small-caps;
  padding: 5px;
  opacity: 0.35;
}

.commissionCardContainer2{

  display: flex;
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 1px;
  box-shadow: 1px 1px 10px lightgray; 
  border-color: lightgray;
  font-variant: small-caps;
  padding: 5px;
  padding: 5px;
  
}


.commissionCardPic2{

  width: 110px;
  height: 82px;
  margin: 1px;
  object-fit: cover;
  object-position: 50% 50%;
  
}

.commissionCardText{

  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 0px;
  line-height: 1;
  
}

.commissionCardTextHeader{

  display: none;
}

.commissionModal{
  
  background-color: white; 
  width: 95vw;
  height: 99%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
  padding: 5px;
  border-radius: 3px;
  overflow: auto;
  font-variant: small-caps;
}

.commissionModalInside{

  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 3px;
  
  padding: 10px;
  box-shadow: 1px 1px 3px rgb(1,1,1);
}

.commissionModalCloseIcon{

  display: flex;
  position: relative;
  margin-left: calc(100% - 25px);
  cursor: pointer;
}

.commissionModalTitle{

  font-size: 20px;
  margin-bottom: 20px;
  margin-top: -20px;
}

.commissionModalSubTitle{

  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 0px;
}





.commissionModalBar{

  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  zoom: 0.7;

}

.commissionModalBarMedia{

  display: flex;
  flex-direction: column;
  align-items: start;
}


.commissionModalDimensionBlock{

  display: flex;
  align-items: center;

  margin-left: 0px;
  margin-top: 25px;
}
.commissionModalEntryText{

  margin: 5px;
  margin-left: 0px;
  font-weight: bold;
}
.commissionModalEntryPrice{

  font-size: 40px;
  margin-top: 25px;
  
  font-weight: bold;
}


.commissionModalEntryUnits{

  position: relative; 
  bottom: 0; 
  font-size: 90%;
  margin-left: 2px;
}

.commissionModalEntryMedia{

 height: 42px;
 width: 50%;
}

.commissionModalEntryDimension{

 width: 50%;
}

.commissionModalEntryDimensionBlock{

  display: flex;
}

.commissionModalMessageText{

 text-align: justify;
 font-weight: 400;
 margin-top: 30px;
 font-size: 15px;
}


.commissionModalMessage{

 width: 100%;
}

.commissionModalSendButton{

margin-top: 10px;
text-align: right;

}



}
